import React from "react";
import {connect} from "react-redux";
import moment from "moment";
import {farmExpiration} from "../../constans/farmExpiration";
import "./_farm-expiration-status.scss"
import {isMobile} from "../../utils/MobileUtils";
import {retryPayment} from "../../actions/braintreeActions";
import ALink from "../basics/alink/ALink";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class FarmExpirationStatus extends React.Component {

    constructor(props) {
        super(props);

        let farm = props.farms.find(item => item.FarmID === props.farm);
        this.state = {
            farm
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.farm !== nextProps.farm) {
            let farm = nextProps.farms.find(item => item.FarmID === nextProps.farm);
            this.setState({
                farm
            })
        }
    }

    getExpirationDays() {
        const {farm} = this.state;
        return farm ? moment(farm.LicenseExpirationDate).diff(moment(), "days") : null;
    }

    getAdminSubscriptionExpirationDays() {
        const {user} = this.props;
        if (user.AdminSubscription) {
            return moment(user.AdminSubscription.LicenseExpirationDate).diff(moment(), "days");
        }
        return null;
    }

    getClassName(isAdminExpiring, isFarmExpiring, farmExpirationDays, adminExpirationDays) {
        let className = "farm-expiration-status";
        if (isMobile()) className += " mobile";
        if ((isAdminExpiring && adminExpirationDays < farmExpiration.TWO_WEEKS) || (isFarmExpiring && farmExpirationDays < farmExpiration.TWO_WEEKS)) className += " error";
        else className += " warning";
        if (isAdminExpiring && isFarmExpiring) className += " expanded";
        return className;
    }

    isFarmExpiring(expirationTime) {
        const {farm} = this.state;
        return !farm ? false : expirationTime < farmExpiration.MONTH && expirationTime > 0
    }

    isAdminExpiring(adminExpirationDays) {
        return adminExpirationDays < farmExpiration.MONTH && adminExpirationDays > 0;
    }

    shouldRenderDiv(isFarmExpiring, isAdminExpiring) {
        return isFarmExpiring || isAdminExpiring;
    }

    onRetryAdminPayment = () => {
        const {user} = this.props;
        if (user.AdminSubscription.SubscriptionStatus === "Canceled" || user.AdminSubscription.SubscriptionStatus === "Expired") {
            this.props.history.push(`/farmSettings/admin/renew`);
        } else {
            this.props.dispatch(retryPayment({clientID: user.ClientID}));
        }
    };

    onRetryFarmPayment = () => {
        const {farm} = this.state;
        if (farm.SubscriptionStatus === "Canceled" || farm.SubscriptionStatus === "Expired") {
            this.props.dispatch({
                type: "CHANGE_FARM"
            });
            this.props.history.push(`/farmSettings/billing/farm/${farm.FarmID}/renew`);
        } else {
            this.props.dispatch(retryPayment({farmID: farm.FarmID}));
        }
    };

    render() {
        let expirationDays = this.getExpirationDays();
        let isFarmExpiring = this.isFarmExpiring(expirationDays);
        let adminExpirationDays = this.getAdminSubscriptionExpirationDays();
        let isAdminExpiring = this.isAdminExpiring(adminExpirationDays);
        if (!this.shouldRenderDiv(isFarmExpiring, isAdminExpiring)) return null;
        const {t} = this.props;
        return (
            <div className={this.getClassName(isAdminExpiring, isFarmExpiring, expirationDays, adminExpirationDays)}>
                {
                    isAdminExpiring &&
                    <div>
                        {i18next.t("farmExpirationTime.adminExpiring", {
                            amount: adminExpirationDays,
                            type: adminExpirationDays === 1 ? t("day") : t("days")
                        })}
                        <ALink onClick={this.onRetryAdminPayment}>{t("farmExpirationTime.retryPayment")}</ALink>
                    </div>
                }
                {
                    isFarmExpiring &&
                    <div>
                        {i18next.t("farmExpirationTime.expiring", {
                            amount: expirationDays,
                            type: expirationDays === 1 ? t("day") : t("days")
                        })}
                        <ALink onClick={this.onRetryFarmPayment}>{t("farmExpirationTime.retryPayment")}</ALink>
                    </div>
                }
            </div>
        );
    }

}

FarmExpirationStatus = connect(state => ({
    farm: state.location.farm,
    farms: state.farms.farms,
    user: state.user.user
}))(FarmExpirationStatus);

FarmExpirationStatus = withTranslation()(FarmExpirationStatus);

export default withRouter(FarmExpirationStatus);