import React from "react";
import LoadingComponent from "../../components/loading/LoadingComponent";
import Card from "../../components/basics/card/Card";
import PropTypes from "prop-types";
import _ from "lodash";
import "./_documents-viewer.scss"
import moment from "moment";


class DocumentsViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timeRead: 0,
            data: undefined,
            loading: true,
            accepted: true
        }
    }

    UNSAFE_componentWillMount() {
        this.fetchEula(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {path} = this.props;
        if (!_.isEqual(path, nextProps.path)) {
            this.fetchEula(nextProps)
        }
    }

    fetchEula = (props = this.props) => {
        let url = props.path;
        this.setState({
            loading: true,
            data: undefined
        }, () => {
            fetch(url).then(res => {
                if(res.status !== 200){
                    return Promise.reject(res);
                }else{
                    return res.text();
                }
            }).then(data => {
                this.setState({
                    data: data,
                    loading: false
                })
            }).catch(e => {
                this.setState({
                    loading: false
                })
            })
        });
    };

    render() {
        const {loading, data} = this.state;
        const {header, time} = this.props;
        return (
            <Card className={"documents-viewer"} style={{minHeight: '10rem'}}>
                {header && <h4 className="align-content-center justify-content-between">{header}{time && <small>{moment(time).format()}</small>}</h4>}
                {
                    loading &&
                    <LoadingComponent isLoading={loading}/>
                }
                {
                    !loading &&
                    <div className={"document-content"} dangerouslySetInnerHTML={{__html: data}}/>
                }
            </Card>
        )
    }

}


DocumentsViewer.propTypes = {
    path: PropTypes.string.isRequired,
    header: PropTypes.string,
    time: PropTypes.number
};

export default DocumentsViewer



