import React, {Component} from "react";
import {CircleMarker, Map, TileLayer} from "react-leaflet";
import "leaflet/dist/leaflet.css";

export class LocationMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timestamp: 0
        }
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({
                timestamp: +new Date()
            }, () => { window.dispatchEvent(new Event('resize'))});
        }, 500);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const {position, zoom} = this.props;
        return (
            <Map ref={(ref) => this.map = ref} dragging={false} scrollWheelZoom={false} zoomControl={false}
                        style={{height: '100%', width: '100%'}}
                        center={position} zoom={zoom}>
                <TileLayer
                    attribution=' '
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
                <CircleMarker center={position}/>
            </Map>
        );
    }
}

export default LocationMap;

LocationMap.defaultProps = {
    zoom: 12.5
};