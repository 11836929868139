import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getSelectedLevel} from "../../../selectors/mapSelector";
import FarmMap from "../../new-farm-map/FarmMap";
import {Fade} from "react-bootstrap";
import FarmMapDialog from "../../new-farm-map/FarmMapDialog";
import {getBuildingsMap} from "../../../selectors/buildingsSelector";

function makeMapStateToProps() {
    return function mapStateToProps(state, props) {
        return {
            level: getSelectedLevel(state, props),
            buildingsMap: getBuildingsMap(state)
        };
    }
}

class BuildingFarmMap extends Component {

    state = {
        event: null,
        show: false,
        left: 0,
        top: 0,
        width: 0,
        selectedPlacementID: ""
    }

    onChamberClick = ({type, id}) => {
        const {buildingsMap} = this.props;
        if (type === "chambers" && buildingsMap.has(id)) {
            const element = document.getElementById(id);
            const rect = element.getBoundingClientRect();
            this.setState({
                show: true,
                left: rect.x,
                top: rect.y,
                width: rect.width,
                selectedPlacementID: id,
                element
            })
        }
    }

    onOutsideClick = () => {
        this.setState({
            show: false
        })
    }

    render() {
        const {level, selectedLevel} = this.props;
        const {show, top, left, width, selectedPlacementID, element} = this.state;
        if (!level) return null;
        return (
            <>
                <FarmMap key={`bg_${selectedLevel}`} farmMap={level.value} onElementClick={this.onChamberClick}/>
                <Fade in={show} unmountOnExit mountOnEnter>
                    <FarmMapDialog left={left} top={top} width={width} PlcmntID={selectedPlacementID}
                                   onOutsideClick={this.onOutsideClick} element={element}/>
                </Fade>
            </>
        );
    }
}

export default connect(
    makeMapStateToProps,
)(BuildingFarmMap);
