import React, {Component} from "react";
import {Col, Row} from "react-bootstrap"
import Card from "../basics/card/Card";
import "./_farm-item.scss"
import GoogleFarmLoc from "./GoogleFarmLoc";
import Button from "../basics/button/Button";
import {hasActiveSubscription} from "../../utils/BraintreeUtils";
import {checkIfUserIsOwner} from "../../utils/NewRolesUtils";
import moment from "moment";
import {farmExpiration} from "../../constans/farmExpiration";
import _ from "lodash"
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import HighlightedText from "../basics/highlighted-text/HighlightedText";

export class FarmItem extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(this.props, nextProps);
    }

    checkIfDisabled() {
        return !hasActiveSubscription(this.props.farm)
    }

    renderSubscriptionEnded = () => {
        const {t} = this.props;
        return (
            <div className={"subscription-ended"}>
                <h1>{t("farms.farmItem.subscriptionExpired")}</h1>
                {checkIfUserIsOwner() && <Button buttonColor={"info"}><h4>{t("farms.farmItem.extend")}</h4></Button>}
            </div>
        )
    };

    getEndingDays() {
        const {farm: {LicenseExpirationDate}} = this.props;
        return moment(LicenseExpirationDate).diff(moment(), "days");
    }

    navigateToFarmClick = ({position}) => {
        if (navigator.platform.indexOf("iPhone") !== -1 || navigator.platform.indexOf("iPod") !== -1 || navigator.platform.indexOf("iPad") !== -1) {
            window.open(`maps://maps.google.com/maps?daddr=${position[0]},${position[1]}&amp;ll=`);
        } else {
            window.open(`https://maps.google.com/maps?daddr=${position[0]},${position[1]}&amp;ll=`);
        }
    };

    render() {
        const {farm: {FarmName, Adr, FarmCord}, t, search, isService, onClick} = this.props;
        let x = 0;
        let y = 0;
        try {
            let cords = FarmCord.split(",");
            if (FarmCord.includes("'")) {

                x = +cords[0].split("'").join(".") || 0;
                y = +cords[1].split("'").join(".") || 0;
            } else {
                x = +cords[0] || 0;
                y = +cords[1] || 0;
            }

        } catch (e) {
        }
        let ended = this.checkIfDisabled();
        let expirationTime = this.getEndingDays();
        return (
            <Col className={"flex-column"} xs={12} sm={6} md={6} lg={6} xl={3}>
                <Card className={`farm-item ${ended ? 'disabled' : isService ? 'service-farm-item' : ''}`} onClick={!isService ? onClick : undefined}>
                    {
                        ended && this.renderSubscriptionEnded()
                    }
                    <div className="farm-map hide-on-small-device sm">
                        <GoogleFarmLoc position={[x, y]}/>
                    </div>
                    <div className="farm-info">
                        <h3 className={"opacity-50"}>{<HighlightedText searchText={search} text={FarmName}/>}</h3>
                        <div className="farm-info-details">
                            <HighlightedText searchText={search} text={Adr}/>
                        </div>
                        {
                            isService &&
                            <Row className="w-100 justify-content-center pt-1 farm-buttons">
                                <Button disabled={x === 0 && y === 0} buttonColor={"success"} buttonStyle={"text"}
                                        onClick={this.navigateToFarmClick.bind(this, {position: [x, y]})}>
                                    <i className="fas fa-location-arrow mr-2"/>
                                    {t("farms.farmItem.navigate")}
                                </Button>
                                <Button buttonStyle={"text"} buttonColor={"success"} onClick={onClick}>
                                    <i className={"fas fa-fw fa-door-open mr-2"}/>
                                    {t("farms.farmItem.visit")}
                                </Button>
                            </Row>
                        }
                    </div>
                    {
                        expirationTime < farmExpiration.MONTH && expirationTime > 0 &&
                        <div
                            className={`farm-expiring-soon ${expirationTime < farmExpiration.TWO_WEEKS ? "error" : "warning"}`}>
                            {i18next.t("farmExpirationTime.expiring", {
                                amount: expirationTime,
                                type: expirationTime === 1 ? t("day") : t("days")
                            })}
                        </div>
                    }
                </Card>
            </Col>
        );

    }
}

export default withTranslation()(FarmItem);
