import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getSelectedLevel} from "../../../selectors/mapSelector";
import {makeGetDevicesByType} from "../../../selectors/deviceSelector";
import {DevType} from "../../../constans/devices";
import FarmMap from "../../new-farm-map/FarmMap";
import {groupDevicesByGatewayID} from "../../../utils/DevicesUtils";
import NewIOT from "../../../IOT/NewIOT";
import DeviceDataProvider from "../../new-farm-map/DeviceDataProvider";
import {isUsingFakeData} from "../../../utils/SettingsUtils";
import sinon from "sinon";
import {fakeStartSendingDeviceState} from "../../../demo/map/fakeFunctions";

const params = {DevType: [DevType.SCALE, DevType.CLIMATE]};

function makeMapStateToProps() {
    const getDevicesByType = makeGetDevicesByType();
    return function mapStateToProps(state, props) {
        return {
            level: getSelectedLevel(state, props),
            devices: getDevicesByType(state, params)
        };
    }
}

class DeviceFarmMap extends Component {

    devicePolling = [];

    componentDidMount() {
        if (isUsingFakeData()) {
            sinon.restore();
            sinon.stub(NewIOT, "startSendingDeviceState").callsFake(fakeStartSendingDeviceState);
            sinon.stub(NewIOT, "createAndSendMessageObject").callsFake(() => {
            });
            sinon.stub(NewIOT, "sendRequestForAggregatedData").callsFake(() => {
            });
        }
        this.startPolling();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {devices} = this.props;
        const {devices: prevDevices} = prevProps;
        if (devices.length !== prevDevices.length) {
            this.startPolling();
        }
    }


    startPolling = () => {
        //wyczyszczneie jesli odpytuje cos
        this.clearPolling();
        const devices = groupDevicesByGatewayID(this.props.devices);
        //pobranie danych
        if (devices) {
            for (let d of devices.values()) {
                if (d[DevType.SCALE].length) {
                    this.devicePolling.push(NewIOT.startSendingDeviceState(d[DevType.SCALE], undefined, undefined, {keepAlive: true}));
                }
                if (d[DevType.CLIMATE].length) {
                    this.devicePolling.push(NewIOT.startSendingDeviceState(d[DevType.CLIMATE], undefined, undefined, {keepAlive: true}));
                }
            }
        }
    };

    clearPolling = () => {
        NewIOT.removeFromInterval(this.devicePolling)
    }

    componentWillUnmount() {
        this.clearPolling();
        if (isUsingFakeData()) {
            sinon.restore();
        }
    }

    render() {
        const {level, selectedLevel} = this.props;
        console.log("deviceFarmMap -> ", this.props);
        if (!level) return null;
        return (
            <>
                <FarmMap key={`cl_${selectedLevel}`} farmMap={level.value} onElementClick={e => console.log(e)}
                         dataProvider={DeviceDataProvider}/>
            </>
        );
    }
}

export default connect(
    makeMapStateToProps,
)(DeviceFarmMap);
